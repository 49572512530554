#gallery_showcase {
  background: url("../../assets/new10.jpg") no-repeat top center/cover;
  /* min-height: 70vh; */
}

.gallery_wrapper_showcase {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  position: relative;
  isolation: isolate;
}

.gallery_wrapper_showcase::after {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

#gallery_showcase .gallery_wrapper_showcase h1 {
  font-size: 3rem;
  color: var(--primary_color);
  font-weight: 400;
}

/* ======= SECTION: GALLERY_UPDATES ======= */

#gallery_update .gallery_update_wrapper {
  padding: 2rem 0;
  background-color: var(--secondary_color);
}

.gallery_update_wrapper .top {
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery_update_wrapper .top p {
  text-align: center;
  color: #f4f4f4;
  /* font-weight: 300; */
}

.gallery_update_wrapper .top p:nth-child(1) {
  font-family: Raleway;
  font-size: var(--head_font);
  color: var(--primary_color);
  padding: 0.5rem 1rem;
}

.gallery_update_wrapper .underline {
  margin-bottom: 1rem;
}

.gallery_update_wrapper .top p:nth-child(3) {
  max-width: 600px;
  padding: 0 1rem;
  color: #f4f4f4;
  line-height: 1.5;
  /* font-family: Raleway; */
}

.gallery_update_wrapper .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem auto;
}

.gallery_update_wrapper .bottom .left {
  width: 50%;
}

/* .gallery_update_wrapper .bottom .right p:nth-child(1) {
  padding: .5rem 1rem;
} */

.gallery_update_wrapper .bottom .left img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: top center;
  border-bottom: 2rem solid var(--primary_color);
}

.gallery_update_wrapper .bottom .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
}

.gallery_update_wrapper .bottom .right .cards .left,
.gallery_update_wrapper .bottom .right .cards .card {
  width: 100%;
}

.gallery_update_wrapper .bottom .right .cards .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.gallery_update_wrapper .bottom .right p {
  /* width: 100%; */
  /* text-align: center; */
  color: #777;
}

.gallery_update_wrapper .bottom .right p:nth-child(1) {
  /* padding: 0 0 1.5rem 0; */
  font-size: var(--head_font);
  color: var(--primary_color);
  font-family: Raleway;
  text-align: center;
}

.gallery_update_wrapper .bottom .right .cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  width: 70%;
}

.gallery_update_wrapper .bottom .right .cards .left img {
  width:100%;
  height: 500px;
}


.gallery_update_wrapper .bottom .right .cards .card {
  text-align: center;
  background-color: black;
  padding: 2rem 4rem;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.gallery_update_wrapper .bottom .right .cards .card .text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .5rem;
}

.gallery_update_wrapper .bottom .right .cards .text:nth-child(2) p{
  align-self: flex-start;
}

.gallery_update_wrapper .bottom .right .cards .card p {
  font-size: 1.2rem;
  max-width: 100%;
  color: #cccccc;
  text-align: left;
  line-height: 1.3;
}

.gallery_update_wrapper .bottom .right .cards .card .soon {
  font-size: 2rem;
  margin-top: .5rem;
}

.gallery_update_wrapper .bottom .right .cards .card .text p:first-child{
  color: var(--primary_color);
  padding-right: .5rem;
}

.gallery_update_wrapper .bottom .right .cards .card .text p:last-child {
  font-size: var(--primary_font);
}

.gallery_update_wrapper .bottom .right .cards .card .btn_container {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.gallery_update_wrapper .bottom .right .cards .card .event_btn {
  background: var(--primary_color);
}

/* ===== PERFORMANCE_GALLERY ======= */
#performance_gallery {
  background-color: var(--grey_color);
}

#performance_gallery .home_gallery_wrapper p {
  color: var(--primary_color);
  font-family:Raleway;
}

#performance_gallery  .umderline {
  background: var(--primary_color);
}

#performance_gallery .home_gallery_wrapper .media_container {
  max-height: 100vh;
  overflow-y: scroll;
}

/* ===== ARCHIVE_GALLERY ======= */
#archive_gallery {
  background-color: var(--secondary_color);
}

#archive_gallery .home_gallery_wrapper p {
  color: var(--primary_color);
  font-family: Raleway;
}

#archive_gallery .home_gallery_wrapper .media_container {
  max-height: 100vh;
  overflow-y: scroll;
}

@media screen and (max-width: 700px) {
  .gallery_wrapper_showcase {
    min-height: 100vh;
  }

  #gallery_showcase .gallery_wrapper_showcase h1 {
    font-size: 2rem;
  }

  .gallery_update_wrapper .bottom {
    padding: 2rem;
  }

  .gallery_update_wrapper .bottom .left,
  .gallery_update_wrapper .bottom .right {
    width: 100%;
  }

  .gallery_update_wrapper .bottom .left img {
    height: 400px;
  }

  .gallery_update_wrapper .bottom .right .cards {
    flex-direction: column;
    gap: 0;
    margin-top: 2rem;
  }

  .gallery_update_wrapper .bottom .right .cards .card {
    margin-bottom: 1.5rem;
  }

  .gallery_update_wrapper .bottom .right .cards {
    width: 100%
  }
}

@media screen and (max-width: 450px) {
  #gallery_showcase .gallery_wrapper_showcase p {
    font-size:2rem;
    color: var(--primary_color);
  }

  .gallery_update_wrapper .top p:nth-child(1) {
    font-size: 1.8rem;
  }

  .gallery_update_wrapper .bottom .right p:nth-child(1) {
    font-size: 1.7rem;
    line-height: 1;
  }
}
