@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Luckiest+Guy&family=Nosifer&family=Press+Start+2P&family=Yatra+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

:root {
 --primary_color: #906312;
 --secondary_color: #1B1B1B;
 --grey_color: #3F3F3F;
 --light_grey: #dfe0e0;
 --light_green: #9ED824;
 --transition: 400ms ease-in-out;
 --box_shadow:  0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07);
 --head_font: 2rem;
 --head_font2: 1.5rem;
--primary_font: 14px;
}


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-align: left;
}

body {
  margin: 0;
  font-family: Raleway ,"Raleway", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  color: var(--grey_color);
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace,'Roboto' 'Raleway';
}

p {
  font-size: var(--primary_font);
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  /* font-family: 'Raleway', sans-serif; */
  font-family: Roboto;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
}

ul {
  list-style: none;
}

img {
  font-style: italic;
  display: block;
  width: 100%;
}

.container {
  max-width: 80%;
  margin: auto;
}

.text_center {
  text-align: center;
}

.text_primary_color {
  color: var(--primary_color);
}

.btn {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 0;
  display: inline-block;
  border-radius: 100vw;
  color: #fff;
  font-size: var(--primary_font);
  font-weight: 300;
  transition : var(--transition);
  cursor: pointer;
  position: relative;
  isolation: isolate;
  line-height: 1;
  overflow: hidden;
  background: none;
}

.btn:hover,
.btn:focus-visible {
  background: transparent;
}

.btn > span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100vw;
  top: 0;
  left: 0;
  background-color: #ffff;
  z-index: -1;
  transform-origin: left;
  transform: scaleX(0);
  transition: all var(--transition);
}

.btn:hover > span,
.btn:focus-visible > span{
  transform: scaleX(1);
}


.btn_main {
  text-decoration: none;
  border: none;
  outline: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  transition : all var(--transition);
  background: var(--dark_blue);
  width: fit-content;
}

.btn_main:hover,
.btn_main:focus{
  opacity: 0.8;
  letter-spacing: 1px;
}

.blue_overlay{
 position: absolute;
 inset: 0;
 z-index: 1;
 background: linear-gradient(-40deg, rgba(33, 120, 252, 0.2) 0%, rgba(0, 130, 199, 0.7091211484593838) 58%, rgba(34, 57, 94, 1) 100%);
}

.underline {
  width: 50px;
  height: 3px;
  border-radius: 5px;
  background: var(--primary_color);
  margin: auto;
  margin-top: 5px;
}

.underline_two {
font-family: 'Roboto', sans-serif;  width: 50px;
  height: 3px;
  border-radius: 5px;
  background: var(--light_green);
  margin-top: 5px;
}