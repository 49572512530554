#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    z-index: 100;
    background-color: transparent;
}

#header.active {
    background-color: rgba(34, 34, 34, 0.7);
}


#header .header_wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: #222; */
    z-index: -500;
    padding: 1rem 0;
    height: 100%;
}

#header .header_wrapper.increase {
    height: 100vh;
}

#header .header_wrapper .nav_logo {
    width: 100px;
}

#header .header_wrapper .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all var(--transition);
    /* background-color: #222; */
}

#header .header_wrapper .header_middle {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 1rem;
    width: 100%;
}

#header .header_wrapper .header_middle a {
    font-size: var(--primary_font);
    padding: 1rem;
    /* font-family: Nosifer; */
    color: #fff;
    font-weight: 300;
    transition: color var(--transition);
    overflow: hidden;
}

#header .header_wrapper .header_middle a:hover,
#header .header_wrapper .header_middle a:focus-visible {
    color: var(--primary_color);
}

#header .header_wrapper .header_right {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}

#header .header_wrapper .header_right .btn{
    background-color: var(--primary_color);
}

#header .header_wrapper .header_right .btn:hover a, 
#header .header_wrapper .header_right .btn:focus-visible a{
    color: var(--grey_color);
}

#header .header_wrapper .header_right a{
    color: #fff;
    font-size: var(--primary_font);
    padding: 0.7rem 1.3rem;
}

#header .header_wrapper .humburger {
    cursor: pointer;
    display: none;
    position: absolute;
    top: 1rem;
    right: 2rem;
}

#header .header_wrapper .humburger .bar {
    transition: all var(--transition);
    width: 30px;
    height: 1px;
    margin: 0.5rem 0;
    background-color: #fff;
    border-radius: 15px;
}

#header .header_wrapper .humburger.animate .bar:first-child {
    transform: rotate(45deg) translate(45%);
    width: 30px;
}

#header .header_wrapper .humburger.animate .bar:nth-child(2) {
   opacity: 0;
}

#header .header_wrapper .humburger.animate .bar:last-child {
    transform: rotate(-45deg) translate(44%);
    width: 30px;
}

/* ======= MEDIA QUERY ======= */
@media screen and (max-width:1035px) {
    #header .header_wrapper .header_middle {
        gap: 0.2rem;
    }

    #header .header_wrapper .header_middle a {
        padding: 0.5rem;
    } 
}

@media screen and (max-width: 700px) {
    #header .header_wrapper {
        flex-direction: column;
        max-width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    #header .header_wrapper .nav_logo {
        width: 88px;
        position: absolute;
        top: 1rem;
        left: 2rem;
    }

    
#header .header_wrapper .links {
    flex-direction: column;
    width: 100%;
    transition: all var(--transition);
    height: 100vh;
    transform: translateY(-100vh);
    background-color: rgba(34, 34, 34);
}

    #header .header_wrapper .links.toggle {
        transform: translateY(0);
    }

    #header .header_wrapper .links.toggle {
        padding: 0.8rem;
    }

    #header .header_wrapper .header_middle {
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
    }

    #header .header_wrapper .humburger {
        display: block;
    }
}